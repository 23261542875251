import React, { FC } from "react";
import styled from "@emotion/styled";

interface PageHeaderProps {
    children: JSX.Element | JSX.Element[]
}

const PageHeader: FC<PageHeaderProps> = ({ children }) => (
    <Wrapper>
        {children}
    </Wrapper>
);

const Wrapper = styled("div")`
    width: 100%;
    height: 120px;
    background-color: darkblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

export default PageHeader;