import React, { useState, FC, useEffect } from 'react';
import SudokuBoardFilled from "./sudoku-board-filled";
import SudokuBoardClean from './sudoku-board-clean';
import { vyresAVratVysledek, vyresAVratKrok, checkSolved } from "../utils/solving-functions";

interface SudokuSolverProps {
    content: number[];
    showValues: boolean;
    nextStep: number;
}


const SudokuSolver: FC<SudokuSolverProps> = ({ content, showValues, nextStep }) => {
    const [submitedContent, setSubmitedContent] = useState<number[]>(content);
    const [firstRender, setFirstRender] = useState(true);

    let mrizka: number[][] = [];
    let justArray: number[] = [];




    for (let i = 0; i < content.length + 1; i++) {
        justArray.push(content[i])
        if (justArray.length === 9) {
            mrizka.push(justArray);
            justArray = [];
        }
    };

    let mrizkaArray: number[] = [];
    for (let i = 0; i < mrizka.length; i++) {
        mrizka[i].forEach(element =>
            mrizkaArray.push(element))
    };

    let badTypeMrizka = vyresAVratVysledek(mrizka);
    let filledMrizka = [];

    for (let i = 0; i < badTypeMrizka.length; i++) {
        filledMrizka = filledMrizka.concat(badTypeMrizka[i]);
    }

    useEffect(() => {
        for (let i = 0; i < submitedContent.length; i++) {
            if (submitedContent[i] !== 0) {
                if (submitedContent[i] !== filledMrizka[i]) {
                    alert("Bohužel Vaše řešení neodpovídá výsledku Sudoku");
                    return;
                }
            }
        }

        solveStep(submitedContent, firstRender);
        setFirstRender(false);
    }, [nextStep]);

    function maxNumberOfOcurences(arr) {
        let res = [];
        let errored = true;

        for (let x of arr) {
            let count = 0;
            for (let i of arr) {
                if (i === x) {
                    count++;
                }
            }
            if (count < 9) {
                res.push(count);
                errored = false;
            } else {
                res.push(0)
            }
        }
        if (errored) {
            let randomNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            randomNumbers = randomNumbers.filter(number => !arr.includes(number));

            return randomNumbers[Math.floor((Math.random() * randomNumbers.length - 1))];
        }

        return arr[res.indexOf(Math.max(...res))];
    }

    function solveStep(submitedContent: number[], firstRender: boolean) {
        let stepMrizka: number[][] = [];
        justArray = [];

        for (let i = 0; i < submitedContent.length; i++) {
            if (!isNaN(submitedContent[i])) {
                justArray.push(submitedContent[i]);
            } else {
                justArray.push(0);
            }
            if (justArray.length === 9) {
                stepMrizka.push(justArray);
                justArray = [];
            }
        };
        if (!firstRender) {
            if (!vyresAVratKrok(stepMrizka)) {
                //undone
                let inMrizkaNumbers: number[] = [];
                let numbersAndIndexes: { value: number, index: number }[] = [];

                for (let i = 0; i < stepMrizka.length; i++) {
                    for (let x = 0; x < stepMrizka[i].length; x++) {
                        if (stepMrizka[i][x] !== 0) {
                            inMrizkaNumbers.push(stepMrizka[i][x]);
                        }
                    }
                }

                for (let i = 0; i < badTypeMrizka.length; i++) {
                    for (let x = 0; x < badTypeMrizka[i].length; x++) {
                        if ((maxNumberOfOcurences(inMrizkaNumbers) === badTypeMrizka[i][x]) && stepMrizka[i][x] === 0) {
                            numbersAndIndexes.push({ value: badTypeMrizka[i][x], index: (9 * i) + x });
                        }
                    }
                }

                let newItem: { value: number, index: number } = numbersAndIndexes[Math.floor(Math.random() * numbersAndIndexes.length)];
                if (!newItem) return;
                for (let i = 0; i < stepMrizka.length; i++) {
                    for (let x = 0; x < stepMrizka[i].length; x++) {
                        if (((9 * i) + x) === newItem.index) {
                            stepMrizka[i][x] = newItem.value;
                        }
                    }
                }
            }
        }

        let stepMrizkaArray: number[] = [];
        for (let i = 0; i < stepMrizka.length; i++) {
            stepMrizka[i].forEach(element =>
                stepMrizkaArray.push(element))
        };
        setSubmitedContent(stepMrizkaArray);
        if (stepMrizkaArray.includes(0)) {
            return;
        }

        checkSolved(stepMrizkaArray);
    };

    let isDefault: boolean[] = new Array(81);

    for (let i = 0; i < content.length + 1; i++) {
        if (content[i] !== 0) {
            isDefault[i] = true
        } else {
            isDefault[i] = false
        }
    };

    return (
        <>
            <SudokuBoardFilled active={showValues} content={filledMrizka} />

            <SudokuBoardClean
                onChange={() => checkSolved(submitedContent)}
                onSudokuValueChange={setSubmitedContent}
                active={!showValues}
                content={submitedContent}
                result={filledMrizka}
                defaultValues={isDefault} />
        </>
    );
}

export default SudokuSolver;