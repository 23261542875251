import React, { FC, useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";

import SudokuCell from "./sudoku-cell";
import Button from "./button";

interface SudokuBoardCleanProps {
    onSudokuValueChange: (par: number[]) => void
    customSudokuActive: boolean
    setCustomSudokuActive: (value: boolean) => void

}

let currentColumn = 0;
let currentRow = 0;
let submitedContent = new Array(81).fill(0);

const SudokuTask: FC<SudokuBoardCleanProps> = ({ onSudokuValueChange, customSudokuActive, setCustomSudokuActive }) => {
    const [activeRow, setActiveRow] = useState<number | null>(null);
    const [activeColumn, setActiveColumn] = useState<number | null>(null);

    const SudokuTasksArray: number[][] = [
        [0, 0, 0, 8, 6, 3, 0, 0, 0,
            0, 3, 0, 0, 0, 0, 0, 0, 0,
            6, 0, 9, 2, 0, 0, 1, 0, 0,
            4, 5, 0, 0, 0, 9, 0, 0, 6,
            0, 2, 0, 0, 0, 0, 0, 1, 0,
            8, 0, 0, 5, 0, 0, 0, 3, 2,
            0, 0, 8, 0, 0, 2, 9, 0, 5,
            0, 0, 0, 0, 0, 0, 0, 2, 0,
            0, 0, 0, 6, 9, 1, 0, 0, 0],
        [
            0, 1, 0, 0, 3, 0, 0, 0, 0,
            7, 0, 0, 0, 4, 0, 1, 0, 0,
            0, 2, 0, 0, 0, 0, 3, 7, 0,
            0, 5, 7, 2, 0, 0, 4, 0, 0,
            0, 9, 0, 5, 0, 0, 2, 0, 0,
            2, 0, 0, 6, 1, 0, 0, 0, 3,
            0, 0, 9, 0, 0, 1, 0, 0, 7,
            0, 0, 2, 4, 9, 0, 5, 0, 1,
            8, 0, 0, 0, 2, 0, 6, 0, 0],

        [
            0, 6, 0, 4, 0, 0, 9, 1, 0,
            0, 4, 2, 0, 0, 1, 6, 8, 7,
            1, 0, 0, 6, 0, 8, 0, 5, 0,
            0, 7, 0, 2, 6, 0, 8, 9, 0,
            0, 0, 0, 3, 0, 4, 0, 0, 6,
            9, 0, 0, 8, 1, 0, 0, 0, 5,
            2, 1, 9, 7, 0, 0, 0, 0, 8,
            7, 0, 0, 5, 8, 0, 0, 0, 0,
            0, 8, 0, 0, 2, 3, 0, 0, 9],
        [
            4, 2, 0, 0, 0, 8, 9, 0, 0,
            9, 0, 0, 0, 4, 2, 0, 0, 7,
            0, 8, 3, 1, 0, 7, 0, 0, 0,
            0, 3, 2, 6, 0, 2, 4, 0, 0,
            5, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 6, 0, 2, 1, 0, 5, 0, 8,
            0, 7, 1, 0, 0, 6, 0, 4, 0,
            2, 5, 6, 4, 0, 0, 8, 0, 1,
            3, 4, 0, 8, 5, 0, 0, 0, 6],

        [
            2, 0, 0, 0, 0, 6, 0, 0, 0,
            1, 9, 5, 3, 0, 4, 0, 8, 7,
            8, 0, 0, 1, 7, 0, 0, 0, 4,
            0, 0, 0, 0, 0, 0, 3, 4, 0,
            0, 0, 0, 8, 1, 0, 0, 2, 0,
            0, 0, 0, 0, 0, 7, 0, 9, 0,
            6, 7, 0, 0, 0, 0, 0, 3, 0,
            0, 0, 0, 0, 5, 0, 0, 7, 0,
            9, 5, 8, 0, 0, 3, 0, 0, 2],]
    //https://sudoku.com/easy/

    const cleanSudokuRef = useRef<HTMLDivElement>(null);

    const onKeyUp = (e: any, thisColumn: number, thisRow: number) => {
        if (!isFinite(e.key) && e.code !== "Backspace") {
            e.preventDefault();
            return;
        }

        if (e.code === "Backspace") {

            let customColumn = thisColumn === 0 ? 9 : thisColumn;
            let customRow = thisRow === 0 ? 9 : thisRow;

            submitedContent[((customRow - 1) * 9) + customColumn - 1] = 0;

            if (thisColumn === activeColumn && thisRow === activeRow) {
                setActiveColumn(null); setActiveRow(null);
            } else {
                setActiveColumn(thisColumn); setActiveRow(thisRow);
            }
            e.preventDefault();
            return;
        }
    };

    const onKeyPress = (e: any) => {
        if ((e.key === "0" || !isFinite(e.key)) && e.code !== "Backspace") {
            e.preventDefault();
            return;
        }
    }

    const onValueChange = (e: any, thisColumn: number, thisRow: number, thisSquare: number) => {
        if ((parseInt(e.target.value, 10) > 9 || parseInt(e.target.value, 10) < 1) || isFinite(e.key)) {
            return;
        }

        let squareValues: number[] = [];

        if (thisSquare <= 3) {
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3], submitedContent[(thisSquare - 1) * 3 + 1], submitedContent[(thisSquare - 1) * 3 + 2]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 9], submitedContent[(thisSquare - 1) * 3 + 10], submitedContent[(thisSquare - 1) * 3 + 11]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 18], submitedContent[(thisSquare - 1) * 3 + 19], submitedContent[(thisSquare - 1) * 3 + 20]]);
        } else if (thisSquare <= 6) {
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 18], submitedContent[(thisSquare - 1) * 3 + 19], submitedContent[(thisSquare - 1) * 3 + 20]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 27], submitedContent[(thisSquare - 1) * 3 + 28], submitedContent[(thisSquare - 1) * 3 + 29]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 37], submitedContent[(thisSquare - 1) * 3 + 38], submitedContent[(thisSquare - 1) * 3 + 39]]);
        } else {
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 36], submitedContent[(thisSquare - 1) * 3 + 37], submitedContent[(thisSquare - 1) * 3 + 38]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 45], submitedContent[(thisSquare - 1) * 3 + 46], submitedContent[(thisSquare - 1) * 3 + 47]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 54], submitedContent[(thisSquare - 1) * 3 + 55], submitedContent[(thisSquare - 1) * 3 + 56]]);
        }

        if (squareValues.includes((parseInt(e.target.value, 10)))) {
            return
        }

        for (let i = 0; i <= 81; i += 9) {
            if (submitedContent[thisColumn + i - 1] === (parseInt(e.target.value, 10))) {
                return
            }
        };

        let thisRowValues = (thisRow === 0 ? submitedContent.slice(71, 80) : submitedContent.slice(thisRow * 9 - 9, thisRow * 9));

        if (thisRowValues.includes(parseInt(e.target.value, 10))) {
            return;
        }

        setActiveColumn(null); setActiveRow(null);
        let customColumn = thisColumn === 0 ? 9 : thisColumn;
        let customRow = thisRow === 0 ? 9 : thisRow;

        if (isNaN(parseInt(e.target.value, 10))) {
            submitedContent[((customRow - 1) * 9) + customColumn - 1] = 0
        } else {
            submitedContent[((customRow - 1) * 9) + customColumn - 1] = parseInt(e.target.value, 10);
        }
    }


    const submitValue = () => {
        onSudokuValueChange(submitedContent);
        submitedContent = new Array(81).fill(0);
    };

    return (
        <>
            {customSudokuActive ? (
                <Wrapper>
                    <CustomSudokuButton onClick={() => { submitedContent = new Array(81).fill(0); setCustomSudokuActive(false) }}>choose sudoku</CustomSudokuButton>
                    <BiggerBoardWrapper ref={cleanSudokuRef}>
                        {submitedContent.map((element, index) => {
                            currentColumn++;
                            if (index % 9 === 0) {
                                currentRow++;
                            };
                            if (currentRow === 9) {
                                currentRow = 0;
                            }
                            if (currentColumn === 9) {
                                currentColumn = 0;
                            };

                            let thisColumn: number = currentColumn;
                            let thisRow: number = currentRow;
                            let thisSquare: number;

                            if (thisColumn <= 3 && thisColumn !== 0) {
                                if (thisRow === 0) {
                                    thisSquare = 7
                                } else if (thisRow <= 3) {
                                    thisSquare = 1
                                } else if (thisRow <= 6) {
                                    thisSquare = 4
                                } else {
                                    thisSquare = 7
                                }
                            } else if (thisColumn <= 6 && thisColumn !== 0) {
                                if (thisRow === 0) {
                                    thisSquare = 8
                                } else if (thisRow <= 3) {
                                    thisSquare = 2
                                } else if (thisRow <= 6) {
                                    thisSquare = 5
                                } else {
                                    thisSquare = 8
                                }
                            } else {
                                if (thisRow === 0) { thisSquare = 9 }
                                else if (thisRow <= 3) {
                                    thisSquare = 3
                                } else if (thisRow <= 6) {
                                    thisSquare = 6
                                } else {
                                    thisSquare = 9
                                }
                            }

                            return (
                                <SudokuCell
                                    onKeyPress={onKeyPress}
                                    active={thisColumn === activeColumn || thisRow === activeRow}
                                    onMouseOver={() => { setActiveRow(thisRow); setActiveColumn(thisColumn) }}
                                    borderBottom={currentRow === 3 || currentRow === 6}
                                    onChange={e => onValueChange(e, thisColumn, thisRow, thisSquare)}
                                    value={element !== 0 && element}
                                    type="number"
                                    onKeyUp={e => onKeyUp(e, thisColumn, thisRow)}
                                    placeholder={"0"}
                                    maxLength={1}
                                    key={index} />
                            )
                        })}
                    </BiggerBoardWrapper>
                    <CustomSudokuButton onClick={submitValue}>set sudoku</CustomSudokuButton>
                </Wrapper>
            ) : (
                <BoardsWrapper>
                    <CustomSudokuButton onClick={() => setCustomSudokuActive(true)}>custom sudoku</CustomSudokuButton>

                    {SudokuTasksArray.map((sudokuTask, index) => {
                        return (
                            <BoardWrapper key={index} onClick={() => onSudokuValueChange(sudokuTask)}>
                                {sudokuTask.map((element, index) => {
                                    currentColumn++;

                                    if (index % 9 === 0) {
                                        currentRow++;
                                    };
                                    if (currentRow === 9) {
                                        currentRow = 0;
                                    }
                                    if (currentColumn === 9) {
                                        currentColumn = 0;
                                    };

                                    return (
                                        <StyledSUDOKUCELL
                                            active={false}
                                            value={element !== 0 && element}
                                            type="number"
                                            key={index}
                                            onKeyDown={(e) => {e.preventDefault()}}
                                            borderBottom={currentRow === 3 || currentRow === 6}
                                        />
                                    )
                                })}
                            </BoardWrapper>
                        )
                    })}
                </BoardsWrapper>
            )}
        </>
    )

};

const CustomSudokuButton = styled(Button)`
    height: 206px;
    max-height: 206px;
    width: 206px;
    max-width: 206px;
    border: 1px solid black;
    margin-bottom: 60px;

    :hover {
        background-color: blue;
        color: white;
    }
`;

const Wrapper = styled("div")`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const BoardsWrapper = styled("div")`
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;

    >*{
        margin-left: 1px;
        margin-right: 1px;
    }
`;

const BoardWrapper = styled("div")`
    height: 206px;
    max-height: 206px;
    width: 206px;
    max-width: 206px;
    margin-bottom: 60px;
    opacity: 0.5;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    :hover {
        opacity: 1;
    }
    
    >input:nth-of-type(3n) {
        border-right: 2px solid blue;
    }

    >input:nth-of-type(9n) {
        border-right: 1px solid gray;
    }
`;

const BiggerBoardWrapper = styled(BoardWrapper)`
    width: 564px;
    max-width: unset;
    height: 564px;
    max-height: unset;
    opacity: 1;

    >input:nth-of-type(3n) {
        border-right: 4px solid gray;
    }

    >input:nth-of-type(9n) {
        border-right: 1px solid gray;
    }
`;


const StyledSUDOKUCELL = styled(SudokuCell) <{ borderBottom: boolean }>`
    border-bottom: ${p => p.borderBottom && "2px solid blue"};
    max-width: 21px;
    max-height: 21px;
    cursor: pointer;

    hover: {
        background-color: white;
    }
`;


export default SudokuTask;