import React, { FC } from "react";
import styled from "@emotion/styled";

interface PageWrapperProps {
    children: JSX.Element | JSX.Element[]
}

const PageWrapper: FC<PageWrapperProps> = ({ children }) => (
    <Wrapper>
        {children}
    </Wrapper>
);

const Wrapper = styled("div")`
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    padding: 60px;
`;


export default PageWrapper;