import React from "react";
import styled from "@emotion/styled";

interface SudokuWrapperProps {
    active?: boolean
    children?: any
    ref: React.LegacyRef<HTMLDivElement>
}

const SudokuWrapper = React.forwardRef<any, SudokuWrapperProps>(({ active, children }, ref) => {

    return (
        <Wrapper ref={ref} active={active}>
            {children}
        </Wrapper>)

});

const Wrapper = styled("div") <{ active: boolean }>`
    height: 564px;
    max-height: 564px;
    width: 564px;
    max-width: 564px;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    >input:nth-of-type(3n) {
        border-right-width: 4px;
    }

    >input:nth-of-type(9n) {
        border-right-width: 1px;
    }

    opacity: ${p => (p.active !== undefined) && p.active ? "1" : "0"};
`;


export default SudokuWrapper;