import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";

import SudokuSolver from "./components/sudoku-solver";
import SudokuTask from "./components/sudoku-task";
import PageHeader from './components/page-header';
import PageWrapper from './components/page-wrapper';
import Button from "./components/button";


function App() {
  const [submitedContent, setSubmitedContent] = useState<number[]>([]);

  const [showResults, setShowResults] = useState(false);
  const [step, setStep] = useState(0);
  const [customSudokuActive, setCustomSudokuActive] = useState(false);

  return (
    <div className="App">
      <PageHeader>
        <Logo onClick={() => { setSubmitedContent([]); setCustomSudokuActive(false) }}>
          <svg width="202" height="202" viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M160.938 43.904C160.938 34.832 154.242 29.864 143.802 29.864C132.21 29.864 124.578 36.848 124.722 47.648H134.37C134.37 39.872 138.834 37.208 143.226 37.208C148.05 37.208 150.93 39.872 150.93 44.408C150.93 52.688 140.562 61.4 123.786 74.936V83H162.162V74.648H135.378C151.434 63.92 160.938 53.696 160.938 43.904Z" fill="white" />
            <path d="M75.8576 158.864H64.1936V115.592H56.6336C54.3296 121.28 49.5776 121.784 42.0176 122V128.696H54.2576V158.864H42.0176V167H75.8576V158.864Z" fill="white" />
            <rect x="96" y="22" width="10" height="158" fill="white" />
            <rect x="180" y="96" width="10" height="158" transform="rotate(90 180 96)" fill="white" />
          </svg>
        </Logo>
        {submitedContent.length !== 0 ?
          <ButtonsWrapper>
            <Button onClick={() => setShowResults(!showResults)}>{showResults ? "Hide results" : "Show results"}</Button>
            <Button onClick={() => setStep(step + 1)}>Next step</Button>
          </ButtonsWrapper> :
          <Label>{customSudokuActive ? "SET YOUR OWN SUDOKU" : "CHOOSE YOUR SUDOKU"}</Label>
        }
      </PageHeader>

      <PageWrapper>
        {submitedContent.length === 0 ?
          (<SudokuTask customSudokuActive={customSudokuActive} setCustomSudokuActive={setCustomSudokuActive} onSudokuValueChange={setSubmitedContent} />)
          :
          (<SudokuSolver nextStep={step} showValues={showResults} content={submitedContent} />)}
      </PageWrapper>
    </div>
  );
};

const Logo = styled("div")`
  position: absolute;
  cursor: pointer;
  max-width: 101px;
  max-height: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
`;

const Label = styled("div")`
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 180px;
  white-space: nowrap;
`;

const ButtonsWrapper = styled("div")`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0px 180px;

  >button {
    margin: 0px 35px;
  }
`;

export default App;