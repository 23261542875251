import React, { FC } from "react";

import SudokuWrapper from "./sudoku-wapper";
import SudokuCell from "./sudoku-cell";

interface SudokuBoardFilledProps {
    content: number[]
    active: boolean
}

const SudokuBoardFilled: FC<SudokuBoardFilledProps> = ({ content, active }) => {

    let currentColumn = 0;
    let currentRow = 0;

    let elements: number[] = [];

    content.forEach((element: number) => {
        elements.push(element)
    });


    return (
        <SudokuWrapper active={active}>
            {elements.map((element, index) => {
                currentColumn++;

                if (index % 9 === 0) {
                    currentRow++;
                };
                if (currentRow === 9) {
                    currentRow = 0;
                }
                if (currentColumn === 9) {
                    currentColumn = 0;
                };

                return (
                    <SudokuCell borderBottom={currentRow === 3 || currentRow === 6} solved={true} active={false} type="number" readOnly={true} key={index} value={element} />
                )
            })}
        </SudokuWrapper>)

};

export default SudokuBoardFilled;