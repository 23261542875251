export interface PossibleValueType {
  [key: string]: number[];
}

function boxRange(number: number): number[] {
  if (number >= 1 && number <= 3) {
    return [1, 2, 3];
  } else if (number >= 4 && number <= 6) {
    return [4, 5, 6];
  }

  return [7, 8, 9];

}

export function testUniquePossibleValue(
  possibleValue: PossibleValueType,
  solution: number[][]
) {
  // pokud je možné jenom jedno čísli, vložíme hodnotu
  for (const [key, value] of Object.entries(possibleValue)) {
    if (value.length === 1) {
      let firstKey = parseInt(key[0], 10);
      let secondKey = parseInt(key[3], 10);
      solution[firstKey - 1][secondKey - 1] = value[0];
      possibleValue[key] = [];
      return true;
    }
  }
}

function testRow(possibleValue: PossibleValueType, solution: number[][]) {
  for (let i = 1; i <= 9; i++) {
    let exist: number[] = solution[i - 1];

    for (let j = 1; j <= 9; j++) {
      let newArrayPossible: number[] = [];
      possibleValue[`${i}, ${j}`].forEach((possibleNumber) => {
        if (!exist.includes(possibleNumber)) {
          newArrayPossible.push(possibleNumber);
        }
      });
      possibleValue[`${i}, ${j}`] = newArrayPossible;
    }

    let possible_element: number[] = [];
    for (let number = 1; number <= 9; number++) {
      possibleValue[`${i}, ${number}`].forEach((element) => {
        if (element !== undefined) {
          possible_element.push(element);
        }
      });
    }

    // otestujeme zda je číslo v listu unikátní
    let unique: number[] = possible_element.filter(function (v) {
      return (
        possible_element.filter(function (v1) {
          return v1 === v;
        }).length === 1
      );
    });

    let filteredValuesAndKeys: PossibleValueType = {};

    // zobrazíme hodnoty pouze s aktivní řady
    let regex = new RegExp(i + ", [1-9]");

    for (let key in Object.keys(possibleValue)) {
      if (key.match(regex)) {
        filteredValuesAndKeys[key] = possibleValue[key];
      }
    }

    // otestujeve jestli je unikatní číslo hodnotou specifických kordinací, přidáme hodnotu
    if (unique.length > 0) {
      for (const uniqueNumber of unique) {
        for (const filteredValueAndKeys of Object.keys(filteredValuesAndKeys)) {
          if (possibleValue[filteredValueAndKeys].includes(uniqueNumber)) {
            solution[parseFloat(filteredValueAndKeys.charAt(0)) - 1][
              parseFloat(filteredValueAndKeys.charAt(3)) - 1
            ] = uniqueNumber;
            possibleValue[filteredValueAndKeys] = [];
            return true;
          }
        }
      }
    }
  }
  return false;
}

function testColumn(possibleValue: PossibleValueType, solution: number[][]) {
  for (let j = 1; j <= 9; j++) {
    let exist: number[] = [];
    solution.forEach((solutionRow) => {
      exist.push(solutionRow[j - 1]);
    });

    for (let i = 1; i <= 9; i++) {
      let possibleValuecopy: number[] = possibleValue[`${i}, ${j}`];
      possibleValue[`${i}, ${j}`] = [];
      possibleValuecopy.forEach((possible_value) => {
        if (!exist.includes(possible_value)) {
          possibleValue[`${i}, ${j}`].push(possible_value);
        }
      });
    }

    let possible_element: number[] = [];
    for (const [key, value] of Object.entries(possibleValue)) {
      let secondKey = parseInt(key[3], 10);
      if (secondKey === j && value.length > 0) {
        value.forEach((value) => possible_element.push(value));
      }
    }

    let unique: number[] = possible_element.filter(function (v) {
      return (
        possible_element.filter(function (v1) {
          return v1 === v;
        }).length === 1
      );
    });
    let filteredValuesAndKeys: PossibleValueType = {};
    // filter out only value from current column
    let regex: RegExp = new RegExp("[1-9], " + j);

    for (let key in Object.keys(possibleValue)) {
      if (key.match(regex)) filteredValuesAndKeys[key] = possibleValue[key];
    }

    if (unique.length > 0) {
      for (const uniqueNumber of unique) {
        for (const filteredValueAndKeys of Object.keys(filteredValuesAndKeys)) {
          if (possibleValue[filteredValueAndKeys].includes(uniqueNumber)) {
            solution[parseFloat(filteredValueAndKeys.charAt(0)) - 1][
              parseFloat(filteredValueAndKeys.charAt(3)) - 1
            ] = uniqueNumber;
            possibleValue[filteredValueAndKeys] = [];
            return true;
          }
        }
      }
    }
  }
  return 0;
}

const range = (min: number, max: number) => {
  const arr: number[] = Array(max - min + 1)
    .fill(0)
    .map((_, i) => i + min);
  return arr;
};

function testBox(possibleValue: PossibleValueType, solution: number[][]) {
  for (let i = 1; i < 8; i += 3) {
    for (let j = 1; j < 8; j += 3) {
      let iRange: number[] = range(i, i + 2);
      let jRange: number[] = range(j, j + 2);
      let exist: number[] = [];
      iRange.forEach((iRange) => {
        jRange.forEach((jRange) => {
          exist.push(solution[iRange - 1][jRange - 1]);
        });
      });

      boxRange(i).forEach((boxRangeI) => {
        boxRange(j).forEach((boxRangeJ) => {
          let possibleValuecopy: number[] =
            possibleValue[`${boxRangeI}, ${boxRangeJ}`];
          possibleValue[`${boxRangeI}, ${boxRangeJ}`] = [];
          possibleValuecopy.forEach((possible_value) => {
            if (!exist.includes(possible_value)) {
              possibleValue[`${boxRangeI}, ${boxRangeJ}`].push(possible_value);
            }
          });
        });
      });

      let possible_element: number[] = [];
      boxRange(i).forEach((boxRangeI) => {
        boxRange(j).forEach((boxRangeJ) => {
          possibleValue[`${boxRangeI}, ${boxRangeJ}`].forEach((element) => {
            if (element !== undefined) {
              possible_element.push(element);
            }
          });
        });
      });
      let unique: number[] = possible_element.filter(function (v) {
        return (
          possible_element.filter(function (v1) {
            return v1 === v;
          }).length === 1
        );
      });

      if (unique.length > 0) {
        for (const uniqueNumber of unique) {
          for (const [key, value] of Object.entries(possibleValue)) {
            let firstKey: number = parseInt(key[0], 10);
            let secondKey: number = parseInt(key[3], 10);

            if (
              value.length > 0 &&
              boxRange(i).includes(firstKey) &&
              boxRange(j).includes(secondKey)
            ) {
              if (value.includes(uniqueNumber)) {
                solution[firstKey - 1][secondKey - 1] = uniqueNumber;
                possibleValue[key] = [];
                return true;
              }
            }
          }
        }
      }
    }
  }
  return 0;
}

export function loopBasicRule(
  possibleValue: PossibleValueType,
  solution: number[][],
  changedGlobal: {
    ch: boolean;
  }
) {
  while (true) {
    if (testRow(possibleValue, solution)) {
      changedGlobal.ch = true;
      break;
    }

    if (testColumn(possibleValue, solution)) {
      changedGlobal.ch = true;
      break;
    }

    if (testBox(possibleValue, solution)) {
      changedGlobal.ch = true;
      break;
    }

    if (testUniquePossibleValue(possibleValue, solution)) {
      changedGlobal.ch = true;
      break;
    }

    // pokud nejsou žádné změny zastavíme algoritmus
    if (!changedGlobal.ch) {
      break;
    }
  }
}

function algorithm(
  possibleList: PossibleValueType,
  possibleValue: PossibleValueType,
  solution: number[][],
  changedGlobal: {
    ch: boolean;
  }
) {
  let listValueArray: number[] = [];
  Object.values(possibleList).forEach((listValue) => {
    listValueArray.push(listValue.length);
  });
  if (
    Math.max(...listValueArray) === -Infinity ||
    Math.min(...listValueArray) === Infinity
  ) {
    return;
  }
  let min_num_possible: number = Math.min(...listValueArray);
  let max_num_possible: number = Math.max(...listValueArray);

  range(min_num_possible, max_num_possible)
    .reverse()
    .forEach((i) => {
      for (const value of Object.values(possibleList)) {
        if (changedGlobal.ch) {
          return true;
        }
        if (value.length === i) {
          let n_subset = 0;
          let key_match = [];
          for (const [key_1, value_1] of Object.entries(possibleList)) {
            if (value.length < value_1.length) {
              continue;
            } else {
              if (value_1.every((val) => value.includes(val))) {
                key_match.push(key_1);
                n_subset++;
              }
            }
            if (n_subset === value.length) {
              for (const [key_2, value_2] of Object.entries(possibleList)) {
                if (changedGlobal.ch) {
                  return true;
                }
                if (!key_match.includes(key_2)) {
                  let emptyArray: number[] = [];
                  value_2.forEach((value2) => {
                    if (!value.includes(value2)) {
                      emptyArray.push(value2);
                    }
                  });
                  possibleValue[key_2] = emptyArray;

                  loopBasicRule(possibleValue, solution, changedGlobal);
                }
              }
            }
          }
        }
      }
  });
}

function alogirthmRow(
  possibleValue: PossibleValueType,
  solution: number[][],
  changedGlobal: {
    ch: boolean;
  }
) {
  for (let i = 1; i <= 9; i++) {
    let possibleList: PossibleValueType = {};
    for (const [key, value] of Object.entries(possibleValue)) {
      let firstKey: number = parseInt(key[0], 10);

      if (value.length > 0 && firstKey === i) {
        possibleList[key] = value;
      }
    }

    algorithm(possibleList, possibleValue, solution, changedGlobal);
  }
}

function alogirthmColumn(
  possibleValue: PossibleValueType,
  solution: number[][],
  changedGlobal: {
    ch: boolean;
  }
) {
  for (let j = 1; j <= 9; j++) {
    let possibleList: PossibleValueType = {};

    for (const [key, value] of Object.entries(possibleValue)) {
      let secondKey: number = parseInt(key[3], 10);
      if (value.length > 0 && secondKey === j) {
        possibleList[key] = value;
      }
    }
    algorithm(possibleList, possibleValue, solution, changedGlobal);
  }
}

export function alogirthmBox(
  possibleValue: PossibleValueType,
  solution: number[][],
  changedGlobal: {
    ch: boolean;
  }
) {
  for (let i = 1; i < 8; i += 3) {
    for (let j = 1; j < 8; j += 3) {
      let possibleList: any = {};
      for (const [key, value] of Object.entries(possibleValue)) {
        let firstKey = parseInt(key[0], 10);
        let secondKey = parseInt(key[3], 10);
        if (
          value.length > 0 &&
          boxRange(i).includes(firstKey) &&
          boxRange(j).includes(secondKey)
        ) {
          possibleList[key] = value;
        }
      }
      algorithm(possibleList, possibleValue, solution, changedGlobal);
    }
  }
}

export function loopAlgorithm(
  possibleValue: PossibleValueType,
  solution: number[][],
  changed2: {
    ch: boolean;
  }
) {
  if(changed2.ch) return;
  while (true) {
    let changed = { ch: false };
    alogirthmRow(possibleValue, solution, changed);
    if(changed.ch){
      changed2.ch = true;
      break;
    }

    alogirthmColumn(possibleValue, solution, changed);
    if(changed.ch){
      changed2.ch = true;
      break;
    }

    alogirthmBox(possibleValue, solution, changed);
    
    if (!changed.ch) {
      break;
    } else {
      changed2.ch = true;
      break;
    }
  }
}

export function testBoxEliminateOthers(possibleValue: PossibleValueType) {
  for (let i = 1; i <= 7; i += 3) {
    for (let j = 1; j <= 7; j += 3) {
      let possibleElements: number[] = [];
      
      for (const [key, value] of Object.entries(possibleValue)) {
                let firstKey = parseInt(key[0], 10);
                let secondKey = parseInt(key[3], 10);
                if (value.length > 0 && boxRange(i).includes(firstKey)
                    && boxRange(j).includes(secondKey)) {
                    for (const specificValue of value) {

                        if (!possibleElements.includes(specificValue)) {
                            possibleElements.push(specificValue);
                        }
                    }

                }
            }

            for (const possibleElement of possibleElements) {
                let available_cells = [];
                for (const [key, value] of Object.entries(possibleValue)) {
                    let firstKey = parseInt(key[0], 10);
                    let secondKey = parseInt(key[3], 10);
                    if (boxRange(i).includes(firstKey) && boxRange(j).includes(secondKey) && value.includes(possibleElement)) {
                        available_cells.push(key);
                    }
                }

                let possibleFirstKeys: string[] = [];
                let possibleSecondKeys: string[] = [];
                for (const aviableCells of available_cells) {
                    if (!possibleFirstKeys.includes(aviableCells[0])) {
                        possibleFirstKeys.push(aviableCells[0]);
                    }
                    if (!possibleSecondKeys.includes(aviableCells[3])) {
                        possibleSecondKeys.push(aviableCells[3]);
                    }
                }

                if (possibleFirstKeys.length === 1) {
                    for (const key of Object.keys(possibleValue)) {
                        if (key[0] === available_cells[0][0] && !available_cells.includes(key)) {
                            let newSpecificValues = [];
                            for (const specificPossibleValue of possibleValue[key]) {
                                if (specificPossibleValue !== possibleElement) {
                                    newSpecificValues.push(specificPossibleValue)
                                }
                            }
                            possibleValue[key] = newSpecificValues;
                        }
                    }
                }

                if (possibleSecondKeys.length === 1) {
                    for (const key of Object.keys(possibleValue)) {
                        if (key[3] === available_cells[0][3] && !available_cells.includes(key)) {
                            let newSpecificValues = [];
                            for (const specificPossibleValue of possibleValue[key]) {
                                if (specificPossibleValue !== possibleElement) {
                                    newSpecificValues.push(specificPossibleValue)
                                }
                            }
                            possibleValue[key] = newSpecificValues;
                        }
                    }
                }
            }
    }
  }
}