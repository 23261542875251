import React, { FC, InputHTMLAttributes } from "react";
import styled from "@emotion/styled";

interface SudokuCellProps extends InputHTMLAttributes<HTMLInputElement> {
    active: boolean
    solved?: boolean
    borderBottom?: boolean
}

const SudokuCell: FC<SudokuCellProps> = ({ active, solved, borderBottom, ...props }) => (
    <Wrapper type="number" solved={solved} borderBottom={borderBottom} active={active} {...props} />
);

const Wrapper = styled("input") <{ active: boolean, solved: boolean, borderBottom: boolean }>`
    max-height: 60px;
    max-width: 60px;
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-bottom-width: ${p => p.borderBottom && "4px"};
    text-align: center;
    font-size: 24px;
    background-color: ${p => p.active && "lightblue"};
    background-color: ${p => p.solved && "lightgreen"};

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :hover {
        background-color: blue;
        color: white;
    }
`;


export default SudokuCell;