import styled from "@emotion/styled";

const Button = styled("button")`
    width: 140px;
    height: 50px;
    outline: none;
    border: none;
    text-transform: uppercase;
    background-color: white;
    color: black;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    white-space: nowrap;

    :hover {
        background-color: lightgray;
    }
`;

export default Button;