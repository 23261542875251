import {
  loopBasicRule,
  loopAlgorithm,
  testBoxEliminateOthers,
  PossibleValueType,
} from "./crook-functions";

//samotna funkce, jez budeme pouzivat v apce s kroky (s vyuzitim Crookova algoritmu)
export function vyresAVratKrok(solution: number[][]): boolean {
  let possible_value: PossibleValueType = {};

  // Nejprve do každé buňky přidámé všechny čísla jako možná
  for (let i = 1; i <= 9; i++) {
    for (let j = 1; j <= 9; j++) {
      let numbers: number[] = [];
      for (let number: number = 1; number <= 9; number++) {
        numbers.push(number);
      }
      possible_value[`${i}, ${j}`] = numbers;
    }
  }

  // Pokud již buňka hodnotu má, odstraníme všechny možné číslo pro tuto buňku
  for (let i = 1; i <= 9; i++) {
    for (let j = 1; j <= 9; j++) {
      if (solution[i - 1][j - 1] !== 0) {
        possible_value[`${i}, ${j}`] = [];
      }
    }
  }

  while (true) {
    let changed = { ch: false };

    loopBasicRule(possible_value, solution, changed);
    if (changed.ch) {
      return true;
    }
    
    loopAlgorithm(possible_value, solution, changed);

    if (changed.ch) {
      return true;
    }

    testBoxEliminateOthers(possible_value);

    if (!changed.ch) {
      return false;
    }
  }
}

// najde dalsi souradnice volneho policka
// tyto souradnice vypise
function najdiDalsiPrazdne(puzzle: number[][]) {
  // pouzivame hodnoty 0-8
  for (let r = 0; r <= 8; r++) {
    for (let s = 0; s <= 8; s++) {
      if (puzzle[r][s] === 0) {
        return [r, s];
      }
    }
  }

  // pokud je sudoku plne vyplneno
  return [null, null];
}

// zjisti jestli je hodnota pro dane souradnice ta spravna
// hodnota je spravna pokud se neopakuje v radku, sloupci a ani v danem 3x3 ctverci
// vypise pravdivost tohoto vyroku
function jeValidni(puzzle: number[][], typ: number, radek: number, sloupec: number) {
  // radek, s kterym pracujeme
  let radekHodnoty: number[] = puzzle[radek];

  // pokud se hodnota v radku opakuje, nase hodnota neni spravna
  if (radekHodnoty.includes(typ)) {
    return false;
  }

  // pokracujeme sloupcem
  let sloupecHodnoty: number[] = [];
  for (let i = 0; i <= 8; i++) {
    sloupecHodnoty.push(puzzle[i][sloupec]);
  }

  // pokud se hodnota ve sloupci opakuje, nase hodnota neni spravna
  if (sloupecHodnoty.includes(typ)) {
    return false;
  }

  // nakonec otestujeme, pokud se hodnota jiz nenaleza v danem 3x3 ctvreci

  let radekStart: number = Math.floor(radek / 3) * 3;
  let sloupecStart: number = Math.floor(sloupec / 3) * 3;

  for (let r = radekStart; r < radekStart + 3; r++) {
    for (let s = sloupecStart; s < sloupecStart + 3; s++) {
      if (puzzle[r][s] === typ) {
        return false;
      }
    }
  }
  // pokud hodnota prosla vsemi tremi podminkami, vypiseme ji do mrizky sudoku¨
  return true;
}

// samotny algoritmus, ktery vyuziva zpetne vyhledavani(backtracking)
// pokud existuje vysledek prepiseme vlozene sudoku na sudoku plne vyplnene
function vyresSudoku(puzzle: number[][]) {
  // souradnice naseho typu
  let radkyAndSloupec: number[] = najdiDalsiPrazdne(puzzle);
  let radek: number = radkyAndSloupec[0];
  let sloupec: number = radkyAndSloupec[1];

  // funkci ukoncime, pokud nemuzeme pokracovat na dalsi radek
  if (radek === null) {
    return true;
  }

  // pokud muzeme pokracovat provedeme typ pro hodnotu hledaneho cisla
  for (let typ = 1; typ <= 9; typ++) {
    // otestujeme spravnost hodnoty
    if (jeValidni(puzzle, typ, radek, sloupec)) {
      // pokud je typ validni, umistime hodnotu do mrizky sudoku
      puzzle[radek][sloupec] = typ;

      // pote cely proces opakujeme
      if (vyresSudoku(puzzle)) {
        return true;
      }

      // pokud zadna hodnota neni validni musime se pomoci zpetneho vyhledavani vratit a vyzkouset jine hodnoty
      puzzle[radek][sloupec] = 0;
    }
  }

  return false;
}

//samotna funkce, jez budeme pouzivat v apce
export function vyresAVratVysledek(mrizka: number[][]) {
  vyresSudoku(mrizka);
  return mrizka;
}

export function checkSolved(submited: number[]) {
  if(submited.includes(0)) {
    return false;
  }
  alert("Sudoku úspěšně vyřešeno!");
  return true;
}
