
import React, { FC, useRef, useState } from "react";
import styled from "@emotion/styled";

import SudokuCell from "./sudoku-cell";


interface SudokuBoardCleanProps {
    content: number[],
    defaultValues: boolean[],
    result: number[],
    active: boolean,
    onSudokuValueChange: (par: number[]) => void
    onChange: () => void
}

let currentColumn = 0;
let currentRow = 0;

const SudokuBoardClean: FC<SudokuBoardCleanProps> = ({ content, defaultValues, active, onSudokuValueChange, onChange, result }) => {

    const cleanSudokuRef = useRef<HTMLDivElement>(null);

    const [activeRow, setActiveRow] = useState<number | null>(null);
    const [activeColumn, setActiveColumn] = useState<number | null>(null);

    let submitedContent: number[] = content;

    const inputHandler = (e: any, index: number, thisColumn: number, thisRow: number) => {

        if (e.key === "Backspace") {
            e.target.value = "";
            submitedContent[index] = 0;
            onSudokuValueChange(submitedContent);
            onChange();
            setActiveColumn(thisColumn);
            setActiveRow(thisRow);
        };

        if (e.keyCode < 97 || e.keyCode > 105) {
            e.preventDefault();
            return;
        };

        const { value, maxLength } = e.target;
        if (String(value).length >= maxLength) {
            e.preventDefault();
            return;
        };
    };


    const onValueChange = (e: any, thisColumn: number, thisRow: number, thisSquare: number) => {
        let userNumber: number = Number(e.target.value);

        // uzivatel nezadal správné číslo vzhledem k řešení
        //if (userNumber !== wantedResult) return;

        let squareValues: number[] = [];

        if (thisSquare <= 3) {
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3], submitedContent[(thisSquare - 1) * 3 + 1], submitedContent[(thisSquare - 1) * 3 + 2]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 9], submitedContent[(thisSquare - 1) * 3 + 10], submitedContent[(thisSquare - 1) * 3 + 11]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 18], submitedContent[(thisSquare - 1) * 3 + 19], submitedContent[(thisSquare - 1) * 3 + 20]]);
        } else if (thisSquare <= 6) {
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 18], submitedContent[(thisSquare - 1) * 3 + 19], submitedContent[(thisSquare - 1) * 3 + 20]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 27], submitedContent[(thisSquare - 1) * 3 + 28], submitedContent[(thisSquare - 1) * 3 + 29]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 37], submitedContent[(thisSquare - 1) * 3 + 38], submitedContent[(thisSquare - 1) * 3 + 39]]);
        } else {
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 36], submitedContent[(thisSquare - 1) * 3 + 37], submitedContent[(thisSquare - 1) * 3 + 38]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 45], submitedContent[(thisSquare - 1) * 3 + 46], submitedContent[(thisSquare - 1) * 3 + 47]]);
            squareValues = squareValues.concat([submitedContent[(thisSquare - 1) * 3 + 54], submitedContent[(thisSquare - 1) * 3 + 55], submitedContent[(thisSquare - 1) * 3 + 56]]);
        }

        if (squareValues.includes(userNumber)) {
            return
        }

        for (let i = 0; i <= 81; i += 9) {
            if (submitedContent[thisColumn + i - 1] === userNumber) {
                return
            }
        };

        let thisRowValues = (thisRow === 0 ? submitedContent.slice(71, 80) : submitedContent.slice(thisRow * 9 - 9, thisRow * 9));

        if (thisRowValues.includes(userNumber)) {
            return;
        }

        setActiveColumn(null); setActiveRow(null);

        for (let i = 0; i < submitedContent.length; i++) {
            if (cleanSudokuRef.current?.children[i]) {
                if (!isNaN(parseInt(cleanSudokuRef.current?.children[i]?.value, 10))) {
                    submitedContent[i] = parseInt(cleanSudokuRef.current?.children[i]?.value, 10);
                } else {
                    submitedContent[i] = 0;
                }
            }
        };

        onSudokuValueChange(submitedContent);
        onChange();
    }

    return (
        <BoardWrapper ref={cleanSudokuRef} active={active}>
            {submitedContent.map((element: number, index: number) => {
                currentColumn++;

                if (index % 9 === 0) {
                    currentRow++;
                };
                if (currentRow === 9) {
                    currentRow = 0;
                }
                if (currentColumn === 9) {
                    currentColumn = 0;
                };

                let thisColumn: number = currentColumn;
                let thisRow: number = currentRow;
                let thisSquare: number;

                if (thisColumn <= 3 && thisColumn !== 0) {
                    if (thisRow === 0) {
                        thisSquare = 7
                    } else if (thisRow <= 3) {
                        thisSquare = 1
                    } else if (thisRow <= 6) {
                        thisSquare = 4
                    } else {
                        thisSquare = 7
                    }
                } else if (thisColumn <= 6 && thisColumn !== 0) {
                    if (thisRow === 0) {
                        thisSquare = 8
                    } else if (thisRow <= 3) {
                        thisSquare = 2
                    } else if (thisRow <= 6) {
                        thisSquare = 5
                    } else {
                        thisSquare = 8
                    }
                } else {
                    if (thisRow === 0) { thisSquare = 9 }
                    else if (thisRow <= 3) {
                        thisSquare = 3
                    } else if (thisRow <= 6) {
                        thisSquare = 6
                    } else {
                        thisSquare = 9
                    }
                }

                return (
                    defaultValues[index] !== true ? (
                        <SudokuCell
                            key={index}
                            borderBottom={currentRow === 3 || currentRow === 6}
                            active={thisColumn === activeColumn || thisRow === activeRow}
                            onMouseOver={() => { setActiveRow(thisRow); setActiveColumn(thisColumn) }}
                            value={element !== 0 && element}
                            onChange={e => onValueChange(e, thisColumn, thisRow, thisSquare)}
                            onMouseLeave={() => { setActiveRow(null); setActiveColumn(null) }}
                            readOnly={false}
                            onKeyDown={e => inputHandler(e, index, thisColumn, thisRow)} maxLength={1} />)
                        :
                        (<SudokuCell
                            key={index}
                            style={{ fontWeight: 550 }}
                            onKeyDown={(e) => { e.preventDefault() }}
                            borderBottom={currentRow === 3 || currentRow === 6}
                            active={thisColumn === activeColumn || thisRow === activeRow}
                            onMouseOver={() => { setActiveRow(thisRow); setActiveColumn(thisColumn) }}
                            onMouseLeave={() => { setActiveRow(null); setActiveColumn(null) }}
                            value={element} />)
                )
            }
            )}
        </BoardWrapper>)

};
const BoardWrapper = styled("div") <{ active: boolean }>`
    height: 564px;
    max-height: 564px;
    width: 564px;
    max-width: 564px;
    position: absolute;
    opacity: ${p => (p.active !== undefined) && p.active ? "1" : "0"};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    >input:nth-of-type(3n) {
        border-right-width: 4px;
    }

    >input:nth-of-type(9n) {
        border-right-width: 1px;
    }
`;

export default SudokuBoardClean;