import React from "react";
import ReactDOM from "react-dom";
import { css, Global } from "@emotion/react";
import App from "./App";

const GlobalStyles = css`

  * {
    margin: 0;
    padding: 0;
    font-family: "HelveticaNeue", sans-serif;
  }

  iframe{
    display: none; 
  }

  html,
  body,
  div#root {
    height: 100%;
  }

  body {
    font-size: 14px;
  }
`;

ReactDOM.render(
  <>
    <Global styles={GlobalStyles} />
    <App />
  </>,
  document.getElementById("root")
);
